import React from "react"
import styles from "../components/header.module.css"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => (
  <Header>
    <div className={styles.mainheading}>Aviva Melamed</div>
    <div className={styles.subheading}>Humanistic Integrative Counsellor</div>
    <div className={styles.box} />
    <div className={styles.menubox}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/about">About Me</Link>
        </li>
        <li className={styles.li}>
          <Link to="/faq">FAQ</Link>
        </li>
        <li className={styles.li}>
          <Link to="/services">Services</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact">Contact Me</Link>
        </li>
      </ul>
    </div>
    <div className={styles.logo} />
    <div className={styles.services}>Services</div>
    <div className={styles.servicestexttop}>
      I offer face to face counselling in the London NW11 area
    </div>
    <div className={styles.servicesimagetop} />
    <div className={styles.servicestextmid}>OR</div>
    <div className={styles.servicestextbottom}>
      Have councelling from the comfort of your own home with secure online
      video conferencing sessions
    </div>
    <div className={styles.servicesimagebottom} />
    <div className={styles.servicesbooktopbox} />
    <div className={styles.servicebooktoptext}>
      <a
        href="mailto:info@avivacounsellingservices.com?Subject=Booking%20Request&body=Date%3A%20%0D%0ATime%3A%20%0D%0ADescription%3A%20"
        target="_top"
      >
        Book Now
      </a>
    </div>
    <div className={styles.servicesbookbottombox} />
    <div className={styles.servicebookbottomtext}>Coming Soon</div>
  </Header>
)
